import { useAPIMutation } from "lib/api"

import { updateUser } from "helpers/api"

import { useCurrentUser } from "features/auth/withAuth"

import { UpdatableAPIUser } from "models/User"

export function useUpdateUser(id: string) {
  let { refreshUser } = useCurrentUser()

  async function call(fields: UpdatableAPIUser) {
    let user = await updateUser({ ...fields, uuid: id })
    if (refreshUser) {
      await refreshUser()
    }
    return user
  }

  let result = useAPIMutation((data: UpdatableAPIUser) => call(data))

  return { ...result, updateUser: result.mutate }
}
