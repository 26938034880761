import * as React from "react"

export default function useScrollPosition() {
  const scrollHeightIsSameAsWindowHeight =
    document.body.scrollHeight === window.innerHeight
  let [isAtBottom, setIsAtBottom] = React.useState(
    scrollHeightIsSameAsWindowHeight
  )

  React.useEffect(() => {
    let handleScroll = () => {
      if (
        document.body.scrollHeight - 64 <
        window.scrollY + window.innerHeight
      ) {
        if (!isAtBottom) {
          setIsAtBottom(true)
        }
      } else {
        if (isAtBottom) {
          setIsAtBottom(false)
        }
      }
    }

    window.addEventListener("scroll", handleScroll, { passive: true })
    return () => window.removeEventListener("scroll", handleScroll)
  }, [isAtBottom, setIsAtBottom])

  return { isAtBottom, scrollHeightIsSameAsWindowHeight }
}
