import * as React from "react"

import {
  Button,
  Checkbox,
  Dialog,
  FormGroup,
  InputGroup,
  Intent,
} from "@blueprintjs/core"
import { MenuItem2 as MenuItem } from "@blueprintjs/popover2"
import { Select2 as Select } from "@blueprintjs/select"

import { toggleItem } from "helpers/array"

import { useUpdateUser } from "./api"
import { User } from "models/User"

const roleOptions = [
  { value: "Attorney" },
  { value: "Paralegal" },
  { value: "Legal Assistant" },
  { value: "Project Manager" },
  { value: "Practice Support" },
  { value: "Administrator" },
  { value: "Other" },
]

const experienceOptions = [
  { value: "<1 year", years: 0 },
  { value: "1 year", years: 1 },
  { value: "2 years", years: 2 },
  { value: "3 years", years: 3 },
  { value: "4 years", years: 4 },
  { value: "5 years", years: 5 },
  { value: "6 years", years: 6 },
  { value: "7 years", years: 7 },
  { value: "8+ years", years: 8 },
]

const areaOptions = [
  "Banking & Finance",
  "Capital Markets & Securities",
  "Emerging Companies & VC",
  "Fund Formation",
  "General Corporate",
  "Mergers & Acquisitions",
  "Real Estate",
  "Technology Transactions",
  "Trusts & Estates",
  "Other",
]

const encodeStartDate = (expValue: string) => {
  let option = experienceOptions.find(({ value }) => value === expValue)

  if (!option) throw Error("option not found")

  let expDate = new Date()

  return `${expDate.getMonth() + 1}/${expDate.getDate()}/${
    expDate.getFullYear() - option.years
  }`
}

const encode = ({
  role,
  experience,
  practiceAreas,
}: {
  role: User["role"]
  experience: User["experience"]
  practiceAreas: User["practice_areas"]
}) => ({
  role,
  experience: encodeStartDate(experience),
  practice_areas: practiceAreas,
})

type MoreUserInfoProps = {
  onClose?: () => void
  user: User
}

export default function MoreUserInfo({
  user,
  onClose = () => null,
}: MoreUserInfoProps) {
  let [values, setValues] = React.useState({
    practiceAreas: user.practice_areas || [],
    role: user.role || "",
    experience: "", // default to empty string so user has to select something so we can find an option in encodeStartDate()
  })

  let { updateUser, isLoading } = useUpdateUser(user.uuid)

  function handleAreaChecked(evt: React.FormEvent<HTMLInputElement>) {
    setValues((prev) => ({
      ...prev,
      practiceAreas: toggleItem(prev.practiceAreas, evt.currentTarget.value),
    }))
  }

  function handleSubmit(evt: React.FormEvent<HTMLFormElement>) {
    evt.preventDefault()
    updateUser({ uuid: user.uuid, ...encode(values) })
  }

  let isValid =
    values.role && values.experience && values.practiceAreas.length > 0

  return (
    <Dialog onClose={onClose} isOpen className="w-112 rounded-none p-0">
      <div
        className="flex h-32 bg-cover bg-left"
        style={{ backgroundImage: `url('/static/Star background.png')` }}
      >
        <div className="color-white m-auto text-lg tracking-widest">
          Let’s update your profile!
        </div>
      </div>
      <div className="p-5">
        <p>
          Every practice group and role at a law firm has different requirements
          and preferences. Help us build a better SimplyAgree for you by letting
          us know about your role at {user.organization_name}:
        </p>
        <form className="py-3" onSubmit={handleSubmit}>
          <FormGroup label="My role:" labelFor="role" inline>
            <Select
              items={roleOptions}
              itemRenderer={(option, { handleClick }) => (
                <MenuItem
                  key={option.value}
                  text={option.value}
                  onClick={handleClick}
                />
              )}
              filterable={false}
              popoverProps={{
                minimal: true,
                targetTagName: "div",
              }}
              onItemSelect={({ value: role }) =>
                setValues((prev) => ({ ...prev, role }))
              }
            >
              <InputGroup
                className="cursor-pointer"
                readOnly
                id="role"
                value={values.role || ""}
                rightElement={<Button icon="caret-down" minimal />}
              />
            </Select>
          </FormGroup>
          <FormGroup label="My experience:" labelFor="experience" inline>
            <Select
              items={experienceOptions}
              itemRenderer={(option, { handleClick }) => (
                <MenuItem
                  key={option.value}
                  text={option.value}
                  onClick={handleClick}
                />
              )}
              filterable={false}
              popoverProps={{
                minimal: true,
                targetTagName: "div",
              }}
              onItemSelect={({ value: experience }) =>
                setValues((prev) => ({ ...prev, experience }))
              }
            >
              <InputGroup
                className="w-24 hover:cursor-pointer"
                readOnly
                id="experience"
                value={values.experience || ""}
                rightElement={<Button icon="caret-down" minimal />}
              />
            </Select>
          </FormGroup>
          <FormGroup
            label="My primary practice/support area(s):"
            labelFor="areas"
          >
            <div className="grid grid-cols-2 text-xs">
              {areaOptions.map((option) => (
                <Checkbox
                  className="mb-1"
                  key={option}
                  value={option}
                  checked={values.practiceAreas.includes(option)}
                  label={option}
                  onChange={handleAreaChecked}
                />
              ))}
            </div>
          </FormGroup>
          <div className="mt-5 flex">
            <Button
              className="ml-auto"
              disabled={!isValid}
              intent={Intent.PRIMARY}
              loading={isLoading}
              onClick={onClose}
              text="Finished"
              type="submit"
            />
          </div>
        </form>
      </div>
    </Dialog>
  )
}
