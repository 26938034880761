import styled from "@emotion/styled"

const Main = styled.main`
  grid-row: auto;
  grid-column: span 1;
`

export const MainIndex = styled.main`
  margin-left: 459px;
  padding-top: 80px;
`

export default Main
