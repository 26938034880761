import * as React from "react"
import MagicGrid from "magic-grid"

type MasonryProps = {
  animate?: boolean
  children?: React.ReactNode
  items?: any[]
  center?: boolean
}

export default function Masonry({ children, ...props }: MasonryProps) {
  let container = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    let grid: MagicGrid | null = null
    let timeout: ReturnType<typeof setTimeout> | null = null
    // magic-grid handles resizing via its own `listen` method
    // unfortunately event listener it creates is not being cleaned up
    // that's why we don't use it and have our own instead
    // see: https://github.com/e-oj/Magic-Grid/issues/24
    const resize = () => {
      if (!timeout)
        timeout = setTimeout(() => {
          grid?.positionItems()
          timeout = null
        }, 200)
    }

    if (!grid) {
      if (container.current) {
        grid = new MagicGrid({
          container: container.current,
          ...props,
          items: props.items?.length,
        })
        window.addEventListener("resize", resize)
      }
    }

    grid?.positionItems()

    return () => {
      window.removeEventListener("resize", resize)
    }
  }, [props.items, props.animate])

  return <div ref={container}>{children}</div>
}
