import { Intent, NonIdealState, Spinner } from "@blueprintjs/core"
import styled from "@emotion/styled"
import cx from "classnames"

import UserEngagement from "app/user-engagement"
import { useAppConfig } from "app/config"

import {
  TransactionBrick,
  TransactionInvitationBrick,
  TransactionSidebar,
  TransactionTopNav,
  TransactionToggleView,
  WhatsNewBrick,
  WhatsNewModal,
} from "components/transactions"

import { apiClient } from "lib/api"

import { deleteTransaction } from "helpers/api"

import MoreUserInfo from "features/user/MoreUserInfo"
import { withAuth } from "features/auth"
import { useCurrentOrgModules, useCurrentUser } from "features/auth/withAuth"
import { ToasterContext } from "helpers/contexts"
import WelcomeEmptyState from "icons/welcome-empty-state.svg"
import EmptyState from "icons/empty-state-generic.svg"
import EmptyIcon from "icons/Empty State - Upload Queue III.svg"
import { MainIndex } from "layouts/mainContent"

import { useContext, useEffect, useState, useMemo } from "react"
import Masonry from "lib/masonry"
import useScrollPosition from "lib/use-scroll-position"
import { useDebounce } from "use-debounce"
import { cAddUser, cAddOrganization } from "app/user-tracking/index"
import { usePrevious } from "helpers/hooks"
import { useLastVisitedTransaction } from "features/transaction/id/api"
import {
  useAllTransactionsCount,
  useTransactions,
  useTransactionInvitations,
  TRANSACTION_PAGE_SIZE,
} from "features/transactions/api"
import { User } from "models/User"
import {
  TransactionSortOptions,
  decode as decodeTransaction,
} from "models/Transaction"
import { SortDirectionType } from "components/menus/SortMenu"

const NoTransactions = styled(NonIdealState)`
  margin-top: 75px;
  justify-content: flex-start;
`

const SpinnerWrapper = styled.div`
  margin-top: 30%;
`

const MasonryWrapper = styled.div`
  padding: 24px 0 48px 0;
`

type IndexProps = {
  user: User
}

function Index({ user }: IndexProps) {
  let { config } = useAppConfig()
  const { toaster } = useContext(ToasterContext)

  const [searchTerm, setSearchTerm] = useState("")
  const [stableSearchTerm] = useDebounce(searchTerm, 500)

  const [lastVisitedTransactionId, setLastVisitedTransactionId] = useState(
    window.localStorage.getItem("lastVisitedTransactionId") || ""
  )
  const [whatsNewModalOpen, setWhatsNewModalOpen] = useState(false)
  const [transactionFilter, setTransactionFilter] = useState<
    "active" | "archived" | "deactivated"
  >("active")

  const [view, setView] = useState<"LIST" | "GRID">("GRID")
  const [orderDirection, setOrderDirection] = useState<SortDirectionType>(null)
  const [orderName, setOrderName] = useState<TransactionSortOptions>("sort")

  const {
    data: lastVisitedTransaction,
    error: lastVisitedTransactionError,
    refetch: refetchLastVisitedTransaction,
    remove,
  } = useLastVisitedTransaction(lastVisitedTransactionId, {
    enabled: !!lastVisitedTransactionId,
    retry: false,
  })

  // NOTE using this query to look for _any_ transactions the user has
  // to determine if this is their first time in the application
  const { data: allTransactionsCount, refetch: revalidateAllTransactions } =
    useAllTransactionsCount()

  useEffect(() => {
    if (Number.isInteger(allTransactionsCount)) {
      UserEngagement.track("TRANSACTIONS_COUNT", {
        count: allTransactionsCount,
      })
    }
  }, [allTransactionsCount])

  const {
    data: transactionPages,
    error,
    isFetchingNextPage: isLoadingMoreTransaction,
    fetchNextPage: loadMoreTransactions,
    hasNextPage: hasMoreTransactions,
    refetch: revalidate,
  } = useTransactions(
    stableSearchTerm,
    transactionFilter,
    orderName,
    orderDirection
  )

  const { data: deactivatedTransactions } = useTransactions(
    "",
    "deactivated",
    orderName,
    orderDirection,
    1
  )

  const transactions = useMemo(
    () => transactionPages?.pages.flatMap((page) => decodeTransaction(page)),
    [transactionPages]
  )

  useEffect(() => {
    if (lastVisitedTransactionError) {
      window.localStorage.removeItem("lastVisitedTransactionId")
      setLastVisitedTransactionId("")
    }
  }, [lastVisitedTransactionError])

  const { isAtBottom, scrollHeightIsSameAsWindowHeight } = useScrollPosition()
  const prevIsAtBottom = usePrevious(isAtBottom)

  useEffect(() => {
    // if the user is on a large screen and has a lot of transactions, keep calling loadMore until
    // we have enough to fill the screen to avoid the spinner constantly spinning with no way to remove it
    if (scrollHeightIsSameAsWindowHeight && hasMoreTransactions) {
      loadMoreTransactions()
    } else if (
      // otherwise, listen for the user to scroll to the bottom of the page and load as necessary
      isAtBottom &&
      !prevIsAtBottom &&
      !isLoadingMoreTransaction &&
      hasMoreTransactions
    ) {
      loadMoreTransactions()
    }
  }, [
    isAtBottom,
    prevIsAtBottom,
    isLoadingMoreTransaction,
    hasMoreTransactions,
  ])

  const { data: invitedTransactions, refetch: revalidateInvitations } =
    useTransactionInvitations()

  useEffect(() => {
    if (toaster && error) {
      toaster.show({
        message: "We ran into a problem loading transactions.",
        intent: Intent.DANGER,
        icon: "error",
      })
    }
  }, [error, toaster])

  const { currentUser } = useCurrentUser()
  const { hasFundingTool, hasClosingTool } = useCurrentOrgModules()

  useEffect(() => {
    if (currentUser) {
      cAddUser(currentUser)
      cAddOrganization(currentUser)
    }
  }, [])

  const isFundingToolOnlyOrg = hasFundingTool && !hasClosingTool

  function handleFilterSelect(value: "active" | "archived" | "deactivated") {
    setTransactionFilter(value)
  }

  function refreshAPIData() {
    if (lastVisitedTransactionId) {
      refetchLastVisitedTransaction()
    }
    revalidate()
    revalidateInvitations()
  }

  async function handleDelete(tId: string) {
    await deleteTransaction(tId)
    // clear from transactions cache
    apiClient.setQueryData(
      [
        "transactions",
        "index",
        stableSearchTerm,
        transactionFilter,
        orderName,
        orderDirection,
      ],
      // TODO-TS: Stuck on these types - assume data.pages is a APITransaction[] type??
      (data) => ({
        ...data,
        pages: data.pages.map((page) => ({
          ...page,
          results: page?.results.filter((t) => t.uuid !== tId),
        })),
      })
    )

    if (tId === lastVisitedTransactionId) {
      // clear from useLastVisitedTransaction query
      remove()
      // remove from local state
      window.localStorage.removeItem("lastVisitedTransactionId")
      // remove from local state
      setLastVisitedTransactionId("")

      revalidate()
      revalidateAllTransactions()
    } else {
      revalidate()
    }
  }

  useEffect(() => {
    if (deactivatedTransactions?.pages.length === 0) {
      setTransactionFilter("active")
    }
  }, [deactivatedTransactions])

  const hasDeactivatedTransactions =
    deactivatedTransactions && deactivatedTransactions?.pages.length > 0

  const activeOnly = transactionFilter === "active"

  const isShowingLastVisitedBrick =
    orderName === "sort" &&
    lastVisitedTransaction &&
    (activeOnly
      ? !lastVisitedTransaction.archived
      : lastVisitedTransaction.archived)

  if (!transactions || allTransactionsCount === undefined || error) {
    return (
      <TransactionSidebar>
        <TransactionTopNav
          searchLoading={searchTerm !== stableSearchTerm}
          onSearchUpdate={setSearchTerm}
          onFilterSelect={handleFilterSelect}
          hasDeactivatedTransactions={hasDeactivatedTransactions}
        />

        <MainIndex>
          <SpinnerWrapper>
            <Spinner intent={Intent.PRIMARY} />
          </SpinnerWrapper>
        </MainIndex>
      </TransactionSidebar>
    )
  }

  let Bricks = [
    ...(isShowingLastVisitedBrick
      ? [
          <TransactionBrick
            {...lastVisitedTransaction}
            createdBy={
              // manually pass in the createdBy for the lastVisitedTransaction, since it's not included in the API response
              transactions.filter((t) => t.id === lastVisitedTransaction.id)[0]
                ?.createdBy ?? null
            }
            lastVisited
            revalidate={refreshAPIData}
            onDelete={handleDelete}
            key={lastVisitedTransaction.id}
            view={view}
            hideClientMatterNumberColumn={isFundingToolOnlyOrg}
          />,
        ]
      : []),
    ...transactions
      .filter((t) =>
        isShowingLastVisitedBrick ? t.id !== lastVisitedTransactionId : true
      )
      .map((attrs) => (
        <TransactionBrick
          {...attrs}
          lastVisited={lastVisitedTransactionId === attrs.id}
          revalidate={refreshAPIData}
          onDelete={handleDelete}
          key={attrs.id}
          view={view}
          hideClientMatterNumberColumn={isFundingToolOnlyOrg}
        />
      )),
  ]

  if (activeOnly) {
    Bricks = [
      ...(invitedTransactions || []).map((attrs) => (
        <TransactionInvitationBrick key={attrs.uuid} view={view} {...attrs} />
      )),
      ...Bricks,
    ]
  }

  if (transactions.length === 0 && invitedTransactions?.length === 0) {
    return (
      <TransactionSidebar>
        <TransactionTopNav
          searchLoading={searchTerm !== stableSearchTerm}
          onSearchUpdate={setSearchTerm}
          onFilterSelect={handleFilterSelect}
          hasDeactivatedTransactions={hasDeactivatedTransactions}
        />

        <MainIndex>
          {allTransactionsCount > 0 ? (
            <div
              className={cx(
                "mx-auto mt-14 flex flex-col items-center text-center",
                {
                  "w-[405px]": !!stableSearchTerm,
                  "w-[320px]": !stableSearchTerm,
                }
              )}
            >
              <h3 className="mb-5 font-normal tracking-wider">
                No{" "}
                {stableSearchTerm
                  ? "matching"
                  : activeOnly
                    ? "active"
                    : "archived"}{" "}
                transactions
              </h3>
              <p className="mb-11 text-base text-gray-550">
                {stableSearchTerm ? (
                  <>
                    Your search does not match any existing transactions. Adjust
                    the Active/Archived filter to expand results.
                  </>
                ) : (
                  <>
                    You can either create a new transaction or choose to view
                    your
                    {activeOnly ? " archived" : " active"} transactions
                  </>
                )}
              </p>
              <EmptyIcon
                className="h-[125px] w-[125px]"
                viewBox="0 0 500 500"
              />
            </div>
          ) : (
            <div className="mx-auto mt-14 flex w-[530px] flex-col items-center text-center">
              <WelcomeEmptyState
                className="h-[200px] w-[200px]"
                viewBox="0 0 500 500"
              />
              <h3 className="mb-5 mt-11 font-normal tracking-wider">
                {`Welcome to SimplyAgree${
                  user.first_name ? `, ${user.first_name}` : ""
                }!`}
              </h3>
              <p className="mb-11 text-base text-gray-550">
                This is your transaction index, but you don’t have any
                transactions yet. Start by creating your first transaction on
                the left side of the screen.
              </p>
            </div>
          )}
        </MainIndex>
      </TransactionSidebar>
    )
  }

  if (Bricks.length <= 0) {
    return (
      <TransactionSidebar>
        <TransactionTopNav
          searchLoading={searchTerm !== stableSearchTerm}
          onSearchUpdate={setSearchTerm}
          onFilterSelect={handleFilterSelect}
          hasDeactivatedTransactions={hasDeactivatedTransactions}
        />

        <MainIndex>
          <NoTransactions
            icon={null}
            title={
              activeOnly ? "No Active Transactions" : "No Archived Transactions"
            }
            description={
              <>
                <p
                  style={{ marginBottom: "50px" }}
                >{`Create a new transaction or view your ${
                  activeOnly ? "archived" : "active"
                } transactions`}</p>

                <EmptyState
                  height="150px"
                  width="150px"
                  viewBox="0 0 500 500"
                />
              </>
            }
          />
        </MainIndex>
      </TransactionSidebar>
    )
  }

  if (view === "GRID" && config?.WHATS_NEW_FLOW_ID && !isFundingToolOnlyOrg) {
    Bricks.splice(
      1,
      0,
      <WhatsNewBrick
        onClick={() => {
          UserEngagement.show(config?.WHATS_NEW_FLOW_ID ?? "")
        }}
        key="whatsNew"
      />
    )
  }

  return (
    <TransactionSidebar>
      <TransactionTopNav
        searchLoading={searchTerm !== stableSearchTerm}
        onSearchUpdate={setSearchTerm}
        onFilterSelect={handleFilterSelect}
        hasDeactivatedTransactions={hasDeactivatedTransactions}
      />

      <WhatsNewModal
        isOpen={whatsNewModalOpen}
        onClose={() => setWhatsNewModalOpen(false)}
      />

      {user?.shouldShowSurvey &&
        (!user.role || !user.experience || !user.practice_areas) && (
          <MoreUserInfo user={user} />
        )}

      <MainIndex>
        <MasonryWrapper>
          <TransactionToggleView
            view={view}
            setView={setView}
            orderDirection={orderDirection}
            setOrderDirection={setOrderDirection}
            orderName={orderName}
            setOrderName={setOrderName}
            hideClientMatterNumberColumn={isFundingToolOnlyOrg}
          />

          {Bricks.length > 0 && (
            <>
              {view === "GRID" && (
                <Masonry
                  center
                  items={Bricks}
                  animate={transactions.length <= TRANSACTION_PAGE_SIZE}
                >
                  {Bricks}
                </Masonry>
              )}
              {view === "LIST" && (
                <div className="mx-14 mb-12 grid place-items-center pt-10">
                  {Bricks}
                </div>
              )}
            </>
          )}
        </MasonryWrapper>
        {hasMoreTransactions && <Spinner intent={Intent.PRIMARY} />}
        <div className="pb-3" />
      </MainIndex>
    </TransactionSidebar>
  )
}

export default withAuth(Index)
